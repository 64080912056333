//todo
// Response 영역에서 null 일 수 있는 값들의 확인 필요
import { makeQuery } from '../../utils/queryUtil';
import { dexServer } from './dexServer';

const getAmountsIn = async (request: GetAmountsInRequest): Promise<GetAmountsInResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/amounts-in',
    query: makeQuery(request.query),
  });
export type GetAmountsInRequest = {
  query: GetAmountsInQuery;
}
type GetAmountsInQuery = {
  amountOut: number;
  fromUnit: string;
  toUnit: string;
}
export type GetAmountsInResponse = string[];

const getAmountsOut = async (request: GetAmountsOutRequest): Promise<GetAmountsOutResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/amounts-out',
    query: makeQuery(request.query),
  });
export type GetAmountsOutRequest = {
  query: GetAmountsOutQuery;
}
type GetAmountsOutQuery = {
  amountIn: number;
  fromUnit: string;
  toUnit: string;
}
export type GetAmountsOutResponse = string[];

const getSwapPairs = async (): Promise<GetSwapPairResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/swap-pair',
  });
export type GetSwapPairResponse = SwapPair[];
type SwapPair = {
  swap_pair_id: number;
  first_wrap_token: WrapToken,
  second_wrap_token: WrapToken,
  first_wrap_token_id: number;
  second_wrap_token_id: number;
  liquidity_pool: string;
  first_reserve_value: string;
  second_reserve_value: string;
  created_at: string;
  updated_at: string;
};
type WrapToken = {
  wrap_token_id: number;
  unit: string;
  micro_chain_id: number;
  contract_address: string;
  owner_micro_chain_id: number;
  owner_micro_chain_currency_id: number;
  created_at: string;
  updated_at: string;
};

const getSlippage = async (): Promise<GetSlippageResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/slippage',
  });
export type GetSlippageResponse = { value: string; }[];

const getReserve = async (request: GetReserveRequest): Promise<GetReserveResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/reserve',
    query: makeQuery(request.query)
  });
export type GetReserveRequest = {
  query: GetPairQuery;
};
type GetPairQuery = {
  isMine: number;
  address?: string;
};
export type GetReserveResponse = Pair[];
type Pair = {
  created_at?: string;
  deleted_at?: string;
  first_reserve_value?: string;
  first_token_ca?: string;
  first_token_currency_id?: number;
  first_token_reserve_value?: string;
  first_token_unit?: string;
  first_token_user_reserve_value?: string
  first_wrap_token_id?: number;
  liquidity_pool?: number;
  second_reserve_value?: string;
  second_token_ca?: string;
  second_token_currency_id?: number;
  second_token_reserve_value?: string;
  second_token_unit?: string;
  second_token_user_reserve_value?: string;
  second_wrap_token_id?: number;
  swap_pair_id?: number;
  updated_at?: string;
};

const getQuote = async (request: GetQuoteRequest): Promise<GetQuoteResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/quote',
    query: makeQuery(request?.query)
  });
export type GetQuoteRequest = {
  query: GetQuoteQuery
};
type GetQuoteQuery = {
  toUnit?: string;
}
export type GetQuoteResponse = Quote[];
type Quote = {
  quote_id?: string;
  from_unit?: string;
  to_unit?: string;
  from_value?: string;
  to_value?: number;
  createdAt?: string;
  updatedAt?: string;
};

const getLiquidityHistory = async (request: GetLiquidityHistoryRequest): Promise<GetLiquidityHistoryResponse> =>
  await dexServer.get({
    path: '/api/v2/dex/liquidity',
    query: makeQuery(request.query)
  });
export type GetLiquidityHistoryRequest = {
  query: GetLiquidityHistoryQuery;
};
type GetLiquidityHistoryQuery = {
  address: string;
  limit: number;
  lastId: number;
};
export type GetLiquidityHistoryResponse = LiquidityHistory[];
type LiquidityHistory = object;

const getDexHistory = async (request: GetDexHistoryRequest): Promise<GetDexHistoryResponse> =>
  await dexServer.get({
    path: '/api/v2/dex',
    query: makeQuery(request.query)
  });
export type GetDexHistoryRequest = {
  query: GetDexHistoryQuery;
};
type GetDexHistoryQuery = {
  address: string;
  limit: number;
  lastId: number;
};
export type GetDexHistoryResponse = DexHistory[];
type DexHistory = object;

const postDexQNA = async (request: PostDexQNARequest): Promise<PostDexQNAResponse> =>
  await dexServer.post({
    path: '/api/v2/dex/qna',
    data: request.data
  });
export type PostDexQNARequest = {
  data: PostDexQNAData
};
type PostDexQNAData = {
  email: string;
  title: string;
  content: string;
};
export type PostDexQNAResponse = {
  content: string;
  created_at: string;
  deleted_at?: string;
  email: string;
  qna_id: string;
  status?: string;
  title?: string;
  updated_at?: string;
};

export {
  getAmountsIn as getAmountsInFromServer,
  getAmountsOut as getAmountsOutFromServer,
  getSwapPairs as getSwapPairsFromServer,
  getReserve as getReserveFromServer,
  getSlippage as getSlippageFromServer,
  getQuote as getQuoteFromServer,
  getLiquidityHistory as getLiquidityHistoryFromServer,
  getDexHistory as getDexHistoryFromServer,
  postDexQNA as postDexQNAFromServer
};
