/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit'

// Adapter 영역 - 선언부
const initialState = {
  address: ''
}

// Slice 영역 - 선언부
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount:  (state, action) => {
      state.address = action.payload.address;
    },
    resetAccount: (state) => {
      state.address = '';
    }
  },
})

// Action 영역
export const {
  setAccount,
  resetAccount
} = accountSlice.actions

// Store 데이터 영역
export const accountInfo = state => state.account

// Reducer 영역
export default accountSlice.reducer
